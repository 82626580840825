.wave-div {
    border: none;
    padding: 0;
    margin: 0;
}

.wave-img2 {
    width: 100%;
    height: 30px;
    background-color: #0E3066;
    border: none;
}