.flyer {
    background-image: url("https://images.pexels.com/photos/1323712/pexels-photo-1323712.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1");
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.flyer-img {
    margin: 10px;
    width: 1110px;
    height: 690px;
}

@media only screen and (max-width: 1205px) {
    .flyer-img {
        margin: 10px;
        width: 840px;
        height: 560px;
    }
}

@media only screen and (max-width: 950px) {
    .flyer-img {
        margin: 10px;
        width: 640px;
        height: 360px;
    }
}

@media only screen and (max-width: 850px) {
    .flyer-img {
        margin: 10px;
        width: 540px;
        height: 260px;
    }
}

@media only screen and (max-width: 600px) {
    .flyer-img {
        margin: 10px;
        width: 480px;
        height: 200px;
    }
}





