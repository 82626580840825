.emperador-div {
    display: flex;
    align-content: center;
    justify-content: center;
    background-color: #F2F2F0;
    width: 100%;
    height: 250px;
    border-top: 1px dashed black;
    padding: 0;
    margin: 0;
}

.emperador-img {
    width: 250px;
    height: 250px;
}

@media only screen and (max-width: 1430px) {
    .emperador-div {
        display: flex;
        align-content: center;
        justify-content: center;
        background-color: #F2F2F0;
        width: 100%;
        height: 200px;
        padding: 0;
        margin: 0;
        margin-bottom: 50px;
    }
    
    .emperador-img {
        width: 200px;
        height: 200px;
    }
}