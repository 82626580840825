.preguntas-frecuentes {
    background: rgb(242,242,240);
    background: linear-gradient(0deg, rgba(242,242,240,1) 25%, rgba(255,255,255,1) 100%);
    display: flex;
    width: 100%;
    height: 100%;
    justify-items: center;
    align-items: center;
    flex-direction: column;
    padding-bottom: 40px;
    padding-top: 100px;
}

.preguntas-frecuentes-h1 {
    font-size: 50px;
    color: #030C29;
    font-weight: 900;
    font-family: 'Nunito', sans-serif;
    font-size: 50px;
    margin-top: 60px;
    margin-bottom: 40px;
}

.preguntas-frecuentes-div {
    display: flex;
    width: 70%;
    height: 100%;
    background-color: rgba(255, 255, 255, .1);
    border: 1px solid #030C29;
    flex-direction: column;
    margin-top: 2px;
    padding: 2px;
    border-radius: 5px;
}

.preguntas-frecuentes-yes {
    cursor: pointer;
    color: black;
    font-size: 20px;
    padding: 10px;
    text-transform: uppercase;
}


.preguntas-frecuentes-yes-rest {
    background-color: rgba(255, 255, 255, .1);
    padding-top: 20px;
    padding-left: 70px;
    padding-right: 50px;
    padding-bottom: 20px;
    color: black;
    font-size: 14px;
    border: 1px solid #011126;
    text-transform: uppercase;
    font-weight: lighter;
    line-height: 140%;
    letter-spacing: 1px;
    font-weight: 500;
}

.preguntas-a {
    color: black;
    font-size: 14px;
    font-weight: bold;
    text-decoration: underline;
}

@media only screen and (max-width: 1205px) {
    .preguntas-frecuentes-h1 {
        font-size: 40px;
    }

    .preguntas-frecuentes-div {
        width: 85%;
        padding-top: 3px;
    }
    .preguntas-frecuentes {
        padding-top: 10px;
    }
}

@media only screen and (max-width: 850px) {
    .preguntas-frecuentes-yes {
        font-size: 14px;
    }
}

@media only screen and (max-width: 600px) {
    .preguntas-frecuentes-h1 {
        font-size: 25px;
    }

    .preguntas-frecuentes-yes {
        font-size: 10px;
    }

    .preguntas-frecuentes-yes-rest {
        font-size: 10px;
    }
    .preguntas-frecuentes {
        padding-top: 10px;
    }
}

@media only screen and (max-width: 450px) {
    .preguntas-frecuentes-h1 {
        font-size: 25px;
        text-align: center;
    }

    .preguntas-frecuentes-yes {
        padding: 4px;
        font-size: 10px;
    }

    .preguntas-frecuentes-yes-rest {
        font-size: 10px;
    }
    
    .preguntas-frecuentes-div {
        width: 98%;
        padding: 0;
    }
}

