.container-garantia {
    width: 100%;
    height: 100%;
    background-color: #F2F2F0;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.container-imagen-garantia {
    padding: 30px;
    width: 400px;
    height: 370px;
}

.garantia-h1 {
    text-align: center;
    font-size: 35px;
    color: #030C29;
    font-weight: 900;
    padding: 40px;
}

.garantia-text {
    margin-left: 280px;
    margin-right: 280px;
    padding: 20px;
    text-align: center;
    font-size: 20px;
    color: #030C29;
    font-weight: 600;
    line-height: 140%;
    font-family: 'Nunito', sans-serif;
    text-transform: uppercase;
}

.garantia-text2 {
    margin-left: 280px;
    margin-right: 280px;
    padding: 20px;
    text-align: center;
    font-size: 16px;
    color: #000000;
    font-weight: 600;
    line-height: 140%;
    font-family: 'Nunito', sans-serif;
    text-transform: uppercase;
}

.garantia-button {
    padding: 15px;
    text-align: center;
    width: 560px;
    height: auto;
    margin-top: 40px;
    background-color: #068D01;
    font-size: 24px;
    color: white;
    font-weight: 900;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    text-transform: uppercase;
    margin-top: 60px;
    margin-bottom: 30px;
    text-shadow: 0px 0px 9px #508AD3;
}

.garantia-button:hover {
    background-color: #21FF21;
}

.garantia-div-button {
    display: flex;
    align-items: center;
    justify-content: center;
}

@media only screen and (max-width: 1500px) {
    .garantia-text {
        margin-left: 280px;
        margin-right: 280px;
        padding: 20px;
        text-align: center;
        font-size: 16px;
        color: #030C29;
        font-weight: 600;
        line-height: 140%;
        font-family: 'Nunito', sans-serif;
        text-transform: uppercase;
    }
    .garantia-text2 {
        margin-left: 280px;
        margin-right: 280px;
        padding: 20px;
        text-align: center;
        font-size: 12px;
        color: #000000;
        font-weight: 600;
        line-height: 140%;
        font-family: 'Nunito', sans-serif;
        text-transform: uppercase;
    }
}

@media only screen and (max-width: 1205px) {
    .garantia-text {
        margin-left: 100px;
        margin-right: 100px;
        font-size: 14px;
    }
    .garantia-text2 {
        margin-left: 100px;
        margin-right: 100px;
        font-size: 12px;
    }
    .container-imagen-garantia {
        padding: 30px;
        width: 300px;
        height: 270px;
    }
    .garantia-h1 {
        font-size: 30px;
    }
}

@media only screen and (max-width: 850px) {
    .garantia-text {
        margin-left: 50px;
        margin-right: 50px;
        font-size: 14px;
    }
    .garantia-text2 {
        margin-left: 50px;
        margin-right: 50px;
        font-size: 12px;
    }
    .container-imagen-garantia {
        padding: 30px;
        width: 300px;
        height: 270px;
    }
    .garantia-h1 {
        font-size: 30px;
    }
}

@media only screen and (max-width: 600px) {
    .garantia-text {
        margin-left: 20px;
        margin-right: 20px;
        font-size: 14px;
    }
    .garantia-text2 {
        margin-left: 20px;
        margin-right: 20px;
        font-size: 12px;
    }
    .container-imagen-garantia {
        padding: 30px;
        width: 250px;
        height: 220px;
    }
    .garantia-h1 {
        font-size: 30px;
    }
    .garantia-button {
        width: 420px;
        margin-top: 40px;
        background-color: #068D01;
        font-size: 14px;
    }
}

@media only screen and (max-width: 450px) {
    .garantia-button {
        width: 320px;
        margin-top: 40px;
        background-color: #068D01;
        font-size: 14px;
    }
}
@media only screen and (max-width: 370px) {
    .garantia-button {
        width: 300px;
        margin-top: 40px;
        background-color: #068D01;
        font-size: 14px;
    }
}

