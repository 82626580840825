.App {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    overflow-x: hidden;
}
.loader {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    height: 100vh;
}
a {
    text-decoration: none;
    list-style: none;
    color: #ffffff;
}