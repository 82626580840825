img.btn-whatsapp {
    display: block;
    position: fixed;
    z-index: 999;
    bottom: 90px;
    left: 20px;
    cursor: pointer;
    border-radius:100px;
    }

img.btn-whatsapp:hover{
    border-radius:100px !important;
    -webkit-box-shadow: 0px 0px 15px 0px rgba(7,94,84,1); 
    -moz-box-shadow: 0px 0px 15px 0px rgba(7,94,84,1);
    box-shadow: 0px 0px 15px 0px rgba(7,94,84,1);
    transition-duration: 1s;
} 