.button-div-call {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.button-call {
    border: none;
    color: #FFFFFF;
    background-color: #06BE05;
    border-radius: 5px;
    line-height: 120%;
    display: inline-block;
    text-decoration: none;
    text-align: center;
    padding: 10px;
    width: 45%;
    height: auto;
    text-transform: uppercase;
    font-size: 30px;
    cursor: pointer;
    font-weight: 700;
    text-shadow: 0px 0px 9px #508AD3;
    align-items: center;
    justify-items: center;
}



.button-call:hover {
    background-color: #21FF21;
}

@media only screen and (max-width: 1430px) {
    .button-call {
        width: 40%;
        font-size: 20px;
    }
}

@media only screen and (max-width: 1205px) {
    .button-call {
        width: 45%;
        font-size: 20px;
    }
}

@media only screen and (max-width: 950px) {
    .button-call {
        width: 50%;
        font-size: 18px;
    }
}

@media only screen and (max-width: 850px) {
    .button-call {
        width: 70%;
        font-size: 16px;
    }
}

@media only screen and (max-width: 600px) {
    .button-call {
        width: 80%;
        font-size: 14px;
    }
}