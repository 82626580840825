.wave-div {
    border: none;
    padding: 0;
    margin: 0;
}

.wave-img {
    width: 100%;
    height: 30px;
    background-color: #011126;
    border: none;
}