  .centrar {
    width: 100%;
  }
  
  .clock-nav {
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    height: 80px;
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .clock-div {
    float: right;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    margin-right: 20px;
  }

  .clock-div-text {
    float: center;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    margin-right: 20px;
  }

  .clock-big {
    color: white;
    font-size: 2rem;
    font-weight: 700;
    text-align: center;
    text-shadow: 0px 0px 9px #508AD3;
  }

  .clock-small {
    color: white;
    font-size: 1rem;
    font-weight: 700;
    text-align: center;
    margin: 10px;
    text-shadow: 0px 0px 9px #508AD3;
  }

  .clock-big-text {
    color: white;
    font-size: 1.4rem;
    font-weight: 900;
    text-align: center;
    margin-top: 15px;
    font-family: helvetica,sans-serif;
    text-shadow: 0px 0px 9px #508AD3;
  }

  @media only screen and (max-width: 1430px) {

    .clock-big-text {
      font-size: 1.1rem;
      margin-top: 18px;
    }
}

@media only screen and (max-width: 1205px) {

  .clock-big-text {
    font-size: 0.8rem;
    margin-top: 18px;
  }

  .clock-big {
    color: white;
    font-size: 1.4rem;
    font-weight: 700;
    text-align: center;
    text-shadow: 0px 0px 9px #508AD3;
  }

  .clock-small {
    color: white;
    font-size: 0.7rem;
    font-weight: 700;
    text-align: center;
    margin: 10px;
    text-shadow: 0px 0px 9px #508AD3;
  }

}

@media only screen and (max-width: 950px) {

  .clock-big-text {
    font-size: 0.8rem;
    margin-top: 10px;
  }

  .clock-big {
    color: white;
    font-size: 1.1rem;
    font-weight: 700;
    text-align: center;
    text-shadow: 0px 0px 9px #508AD3;
  }

  .clock-small {
    color: white;
    font-size: 0.4rem;
    font-weight: 700;
    text-align: center;
    margin: 10px;
    text-shadow: 0px 0px 9px #508AD3;
  }

}

@media only screen and (max-width: 850px) {

  .clock-big-text {
    font-size: 0.6rem;
    margin-top: 10px;
  }

  .clock-big {
    color: white;
    font-size: 1.1rem;
    font-weight: 700;
    text-align: center;
    text-shadow: 0px 0px 9px #508AD3;
  }

  .clock-small {
    color: white;
    font-size: 0.4rem;
    font-weight: 700;
    text-align: center;
    margin: 10px;
    text-shadow: 0px 0px 9px #508AD3;
  }
  .clock-nav {
    height: 60px;
  }
}

@media only screen and (max-width: 690px) {

  .clock-small {
    margin: 10px;
  }

  .clock-big-text {
    font-size: 0.5rem;
  }
  .clock-div {
    margin-right: 5px;
  }
  .clock-div-text {
    margin-right: 5px;
  }
}

@media only screen and (max-width: 560px) {
  .clock-nav {
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    height: 80px;
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .clock-div-text {
    display: flex;
    text-align: center;
    justify-self: center;
    align-content: center;
    margin-right: 0;
    float: none;
  }
  .clock-big-text {
    color: white;
    font-size: 0.6rem;
    font-weight: 900;
    text-align: center;
    margin-top: 5px;
    justify-content: center;
    align-items: center;
  }
}

@media only screen and (max-width: 450px) {

  .clock-div-text {
    display: flex;
    text-align: center;
    justify-self: center;
    align-content: center;
    margin-right: 0;
    float: none;
  }
  .clock-big-text {
    color: white;
    font-size: 0.5rem;
    font-weight: 900;
    text-align: center;
    margin-top: 5px;
    justify-content: center;
    align-items: center;
  }
}