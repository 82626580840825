.button-div-test {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: #0E3066;
    padding-bottom: 100px;
    border: none;
}

.button-test {
    border: none;
    color: #FFFFFF;
    background-color: #06BE05;
    border-radius: 5px;
    line-height: 120%;
    display: inline-block;
    text-decoration: none;
    text-align: center;
    padding: 10px;
    width: 45%;
    height: auto;
    text-transform: uppercase;
    font-size: 24px;
    cursor: pointer;
    font-weight: 700;
    text-shadow: 0px 0px 9px #508AD3;
}

.button-test:hover {
    background-color: #21FF21;
}

@media only screen and (max-width: 1205px) {
    
    .button-test {
        padding: 10px;
        width: 55%;
        height: auto;
        font-size: 20px;
    }

    .button-div-test {
        padding-bottom: 15px;
    }
}

@media only screen and (max-width: 850px) {
    
    .button-test {
        padding: 10px;
        width: 75%;
        font-size: 18px;
    }
}

@media only screen and (max-width: 600px) {
    
    .button-test {
        padding: 10px;
        width: 85%;
        font-size: 16px;
    }
}

@media only screen and (max-width: 450px) {
    
    .button-test {
        padding: 8px;
        width: 90%;
        font-size: 14px;
    }

}
